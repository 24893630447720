html {
  font-size: 2vw;
}
body { margin: 0; }

#app {
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

#app > div:first-child {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: clip;
}

header {
  padding: 0.3rem;
  background-color: beige;
  transition: transform 1s;
  display: flex;
  align-items: center;
}
header h1, header h2 {
  font-size: 2rem;
  margin: 0;
}
header :first-child {
  margin-right: 1rem;
}
header :last-child {
  margin-left: auto;
}

.playing header {
  transform: translateY(-100%)
}

.GameMain {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  font-size: 2rem;
  overflow-y: clip;
}
.score_block {
  display: flex;
  justify-content: space-evenly;
  width: 100%
}

#share {
  display: none;
  padding-left: 0.5rem;
  fill: magenta;
}
#share.show {
  display: inline;
  animation: share 1s infinite alternate;
}

@keyframes share {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

dialog.share_dialog {
  position: absolute;
  inset: 0;
  margin: auto;
}

.share_dialog input {
  width: 100%;
  font-size: 2vw;
}

@keyframes score_get {
  from {
    font-size: 3rem;
    bottom: 50vh;
    left: 70vw;
    opacity: 0;
  }
  10% {
    font-size: 4rem;
    opacity: 1;
  }
  to {
    font-size: 1rem;
    bottom: 60vh;
    left: 75vw;
    opacity: 0;
  }

}
.score_get {
  position: absolute;
  animation: score_get 2s ease-in-out;
}

button.play {
  font-size: inherit;
  border-radius: 1rem;
  padding: 1rem;
}
.question {
  overflow-y: clip;
  text-align: center;
  width: 100%;
}
.question img {
  height: 100%;
}

.answer {
  font-size: 3rem;
  text-align: center;
  width: 100%;
}

.keyboard .row {
  display: flex;
}
.keyboard .row .key {
  flex: 1;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 4vw;
  user-select: none;
}
.key#key-Backspace {
  font-size: 3vw;
}
.keyboard .row .half {
  flex: 0.5;
}
.key.active {
  background: #ccc;
}
.key.hint {
  background: aqua;
  transition: background-color 1s ease-in-out 2s;
}

@keyframes next_key {
  from {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

ruby.next {
  color: gray;
  animation: next_key 2s;
}

ruby.yet {
  opacity: 0;
}

ruby.in {
  color: blue;
}
ruby.in .roman {
  color: gray;
}
ruby.ng {
  color: red;
}
